import React, { FC } from 'react';

import { LoginLayout, InnerBox } from '../Login/LoginLayout';

export const signUpContainer: FC = () => (
  <LoginLayout>
    <InnerBox>
      <div>
        <div className="form-text">
          <div className="form-text-heading">Sign Up Instructions</div>
          <div className="form-text-subheading">
            <ol>
              <li>
                You will receive an invite on your company E-Mail ID once your System Administrator generates/approves
                your ID.
              </li>
              <li>Click on "Accept Invitation" or on the Link provided in the E-Mail</li>
              <li>Sign in with your Company E-Mail ID and Password</li>
              <li>
                Please note that Rheo platform is available only through the Google Chrome Browser. Please use{' '}
                <a href="https://www.google.com/intl/en_us/chrome/" target="__blank">
                  Google Chrome
                </a>
              </li>
            </ol>
          </div>
        </div>
        <div style={{ marginTop: '20px' }}>
          <a href="/login">
            <button className="submit-btn-inverse">
              <i className="fa fa-arrow-left"></i>
              Back to Login
            </button>
          </a>
        </div>
      </div>
    </InnerBox>
  </LoginLayout>
);

export default signUpContainer;
