import React from 'react';

import { Props } from '@grafana/ui/src/components/BigValue/BigValue';
import { buildLayout } from '@grafana/ui/src/components/BigValue/BigValueLayout';
import { FormattedValueDisplay } from '@grafana/ui/src/components/FormattedValueDisplay/FormattedValueDisplay';
import { css } from 'emotion';
import { stylesFactory } from '@grafana/ui/src/themes';
import { defaultFontSize } from './types';

interface BigValueWithImageProps extends Props {
  imageUrl?: string;
  fontSize?: string;
}

export const withImage = (BigValue: any) => {
  return class extends React.Component<BigValueWithImageProps> {
    constructor(props: any) {
      super(props);
    }

    render() {
      const { imageUrl, width, fontSize, height, sparkline } = this.props;
      const layout = buildLayout(this.props);
      const style = getStyles();
      const titleStyles = layout.getTitleStyles();
      const textValues = layout.textValues;
      const valueStyles = layout.getValueStyles();

      const imageTitleValueContainerHeight =
        !!sparkline && height > 100 ? height * 0.75 : !!sparkline && height > 50 ? height * 0.5 : height;

      // Overriding the dynamic fontsize with selected size
      valueStyles.fontSize = defaultFontSize * Number(fontSize);

      return (
        <div>
          <div
            className={style.imageTitleValueContainer}
            style={{ width: width, height: imageTitleValueContainerHeight }}
          >
            {imageUrl ? (
              <div className={style.imageWrapper}>
                <img className={style.image} src={imageUrl} alt="Panel Image" />
              </div>
            ) : (
              ''
            )}
            <div className={style.titleValueWrapper}>
              {textValues.title && (
                <div className={style.title} style={titleStyles}>
                  {textValues.title}
                </div>
              )}
              <FormattedValueDisplay value={textValues} style={valueStyles} />
            </div>
          </div>
          <BigValue {...this.props} />
        </div>
      );
    }
  };
};

const getStyles = stylesFactory(() => {
  return {
    imageTitleValueContainer: css`
      position: absolute;
      top: 0;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      padding: 12px;
    `,
    imageWrapper: css`
      width: 40%;
      display: flex;
      align-items: center;
      height: 100%;
      justify-content: center;
    `,
    image: css`
      max-height: 100%;
      max-width: 100%;
    `,
    titleValueWrapper: css`
      display: flex;
      width: 60%;
      flex-direction: column;
      text-align: center;
      align-self: center;
    `,
    title: css`
      text-align: center;
    `,
  };
});
