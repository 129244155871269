import React, { Component, ChangeEvent } from 'react';
import { Input, Button, IconButton } from '@grafana/ui';
import { SimpleTableOptions } from './SimpleTable';

interface TableBuilderProps {
  data: string;
  onUpdate: (value?: string) => void;
}

interface TableBuilderState {
  tableData: SimpleTableOptions;
  addColumn: boolean;
  columnName: string;
  showDeleteColumn: number;
}

export const defaultTableData: SimpleTableOptions = {
  columns: ['Column 1', 'Column 2'],
  rows: [['Value', 'Value']],
  title: 'Title',
};

export const parseJsonData = (str: string): SimpleTableOptions => {
  const defaultData = JSON.parse(JSON.stringify(defaultTableData));
  try {
    const data = JSON.parse(str);
    return data.hasOwnProperty('columns') && data.hasOwnProperty('rows') && data.hasOwnProperty('title')
      ? data
      : defaultData;
  } catch (e) {
    return defaultData;
  }
};

class TableBuilder extends Component<TableBuilderProps, TableBuilderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      tableData: parseJsonData(props.data),
      addColumn: false,
      columnName: '',
      showDeleteColumn: null,
    };
  }

  updateTableData = () => {
    this.props.onUpdate(JSON.stringify(this.state.tableData));
  };

  toggleAddColumn = () => {
    this.setState({
      addColumn: !this.state.addColumn,
      columnName: '',
    });
  };

  onColumnNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      columnName: event.target.value,
    });
  };

  onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        tableData: {
          ...this.state.tableData,
          title: event.target.value,
        },
      },
      () => {
        this.updateTableData();
      }
    );
  };

  onColumnNameUpdate = (index: number, name: string) => {
    const { tableData } = this.state;
    const { columns } = tableData;
    columns[index] = name;
    this.setState(
      {
        tableData: {
          ...tableData,
          columns: columns,
        },
      },
      () => {
        this.updateTableData();
      }
    );
  };

  onAddColumn = () => {
    const { tableData, columnName, addColumn } = this.state;
    if (!columnName) {
      return;
    }
    const { rows } = tableData;
    rows.forEach((row: any[], index: number) => {
      row = [...row, ''];
      rows[index] = row;
    });
    this.setState(
      {
        tableData: {
          ...tableData,
          columns: [...tableData.columns, columnName],
          rows: rows,
        },
        columnName: '',
        addColumn: !addColumn,
      },
      () => {
        this.updateTableData();
      }
    );
  };

  onTableDataChange = (row_index: number, row_item_index: number, value: string) => {
    const { tableData } = this.state;
    let { rows } = tableData;
    rows[row_index][row_item_index] = value;
    this.setState(
      {
        tableData: {
          ...tableData,
          rows: rows,
        },
      },
      () => {
        this.updateTableData();
      }
    );
  };

  toggleDeleteColumnBtn = (index: number) => {
    this.setState({
      showDeleteColumn: index,
    });
  };

  onDeleteColumn = (index: number) => {
    const { tableData } = this.state;
    const { columns } = tableData;
    let { rows } = tableData;
    columns.splice(index, 1);
    if (columns.length === 0) {
      rows = [];
    } else {
      rows.forEach((row: any[], row_index) => {
        row.splice(index, 1);
        rows[row_index] = row;
      });
    }
    this.setState(
      {
        tableData: {
          ...tableData,
          columns: columns,
          rows: rows,
        },
        showDeleteColumn: null,
      },
      () => {
        this.updateTableData();
      }
    );
  };

  onDeleteRow = (index: number) => {
    const { tableData } = this.state;
    const { rows } = tableData;
    rows.splice(index, 1);
    this.setState(
      {
        tableData: {
          ...tableData,
          rows: rows,
        },
      },
      () => {
        this.updateTableData();
      }
    );
  };

  onAddRow = () => {
    const { tableData } = this.state;
    if (tableData.columns.length > 0) {
      this.setState({
        tableData: {
          ...tableData,
          rows: [...tableData.rows, tableData.columns.map(() => '')],
        },
      });
    }
  };

  render() {
    const { addColumn, columnName, tableData, showDeleteColumn } = this.state;
    return (
      <div className="text-table-container">
        <div className="title-wrapper">
          <Input placeholder="Title" width={30} onChange={this.onTitleChange} value={tableData.title} />
        </div>
        <div className="p-y-1">
          {addColumn ? (
            <div className="align-items-center">
              <Input placeholder="New column name" width={30} onChange={this.onColumnNameChange} value={columnName} />
              <Button className="m-l-1" size="sm" variant="secondary" onClick={this.toggleAddColumn}>
                Cancel
              </Button>
              <Button className="m-l-1" size="sm" onClick={this.onAddColumn}>
                Update
              </Button>
            </div>
          ) : (
            <Button size="sm" variant="secondary" onClick={this.toggleAddColumn}>
              Add Column
            </Button>
          )}
        </div>

        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>{tableData.columns.length > 0 ? '#' : 'Please add columns and rows'}</th>
              {tableData.columns.map((column: string, index: number) => (
                <th key={index}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0.5em 0' }}>
                    <Input
                      onFocus={() => this.toggleDeleteColumnBtn(index)}
                      className=""
                      onChange={(event: ChangeEvent<HTMLInputElement>) =>
                        this.onColumnNameUpdate(index, event.target.value)
                      }
                      value={column}
                    />
                    {showDeleteColumn === index && (
                      <span>
                        <IconButton
                          style={{ color: '#c4162a' }}
                          onClick={() => this.onDeleteColumn(index)}
                          className="p-a-1"
                          name="trash-alt"
                          size="sm"
                          tooltip="Delete column"
                        />
                      </span>
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row: any[], row_index: number) => {
              return (
                <tr key={row_index}>
                  <td>{row_index + 1}</td>
                  {row.map((row_item: string, row_item_index: number) => {
                    const value = tableData.rows[row_index][row_item_index];
                    return (
                      <td key={row_item_index}>
                        <Input
                          onChange={(event: ChangeEvent<HTMLInputElement>) =>
                            this.onTableDataChange(row_index, row_item_index, event.target.value)
                          }
                          value={value}
                        />
                      </td>
                    );
                  })}
                  <td>
                    <IconButton
                      style={{ color: '#c4162a' }}
                      onClick={() => this.onDeleteRow(row_index)}
                      className="p-a-1"
                      name="times"
                      size="sm"
                      tooltip="Delete row"
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="m-t-1">
          <Button size="sm" variant="secondary" onClick={this.onAddRow}>
            Add row
          </Button>
        </div>
      </div>
    );
  }
}

export default TableBuilder;
