import React from 'react';
import _ from 'lodash';
import SignIn from './SignIn';
import BottomNavLinks from './BottomNavLinks';
import { contextSrv } from 'app/core/services/context_srv';
import config from '../../config';
import { NavModelItem } from '@grafana/data';
import { Icon } from '@grafana/ui';

export default function BottomSection({ updateZoom }: any) {
  const navTree: NavModelItem[] = _.cloneDeep(config.bootData.navTree);
  const bottomNav: NavModelItem[] = _.filter(navTree, item => item.hideFromMenu);
  const isSignedIn = contextSrv.isSignedIn;
  const user = contextSrv.user;

  if (user && user.orgCount > 1) {
    const profileNode: any = _.find(bottomNav, { id: 'profile' });
    if (profileNode) {
      profileNode.showOrgSwitcher = true;
    }
  }

  return (
    <div className="sidemenu__bottom">
      {!isSignedIn && <SignIn />}
      <div className="zoom_wrapper">
        <span
          onClick={() => {
            const zoom: string = localStorage.getItem('zoom') || '1';
            updateZoom(parseFloat(zoom) + 0.1);
          }}
        >
          <Icon name={'fa fa-search-plus' as any} size="xl" />
        </span>
        <span
          onClick={() => {
            const zoom: string = localStorage.getItem('zoom') || '1';
            updateZoom(parseFloat(zoom) - 0.1);
          }}
        >
          <Icon name={'fa fa-search-minus' as any} size="xl" />
        </span>
      </div>
      {bottomNav.map((link, index) => {
        return <BottomNavLinks link={link} user={user} key={`${link.url}-${index}`} />;
      })}
    </div>
  );
}
