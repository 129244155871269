import { TimeOption } from '@grafana/data';

export const quickOptions: TimeOption[] = [
  { from: 'now-1h', to: 'now', display: 'Last 1 hour', section: 3 },
  { from: 'now-3h', to: 'now', display: 'Last 3 hours', section: 3 },
  { from: 'now-6h', to: 'now', display: 'Last 6 hours', section: 3 },
  { from: 'now-12h', to: 'now', display: 'Last 12 hours', section: 3 },
  { from: 'now-24h', to: 'now', display: 'Last 24 hours', section: 3 },
  { from: 'now-2d', to: 'now', display: 'Last 2 days', section: 3 },
  { from: 'now-7d', to: 'now', display: 'Last 7 days', section: 3 },
  { from: 'now-30d', to: 'now', display: 'Last 30 days', section: 3 },
  { from: 'now-1M/M', to: 'now-M/1M', display: 'Last Month', section: 3 },
  { from: 'now/M', to: 'now', display: 'This Month', section: 3 },
];

export const quickViewOptions: TimeOption[] = [
  {
    from: 'now/d',
    to: 'now',
    display: 'Today',
    section: 3,
  },
  {
    from: 'now-12h',
    to: 'now',
    display: 'Last 12h',
    section: 3,
  },
  {
    from: 'now-24h',
    to: 'now',
    display: 'Last 24h',
    section: 3,
  },
  {
    from: 'now-1w/w',
    to: 'now/w',
    display: 'Last W',
    section: 3,
  },
  {
    from: 'now/w',
    to: 'now',
    display: 'This W',
    section: 3,
  },
];

export const otherOptions: TimeOption[] = [];
