import { PanelPlugin } from '@grafana/data';

//import { contextSrv } from 'app/core/core';
import { TextPanel } from './TextPanel';
import { TextOptions } from './types';
import { textPanelMigrationHandler } from './textPanelMigrationHandler';
import { TextPanelEditor } from './TextPanelEditor';
//import { OrgRole } from 'app/types';

const options = [
  { value: 'markdown', label: 'Markdown' },
  { value: 'table', label: 'Table' },
  { value: 'html', label: 'HTML' },
];

// if (contextSrv && contextSrv.user && (contextSrv.user.isGrafanaAdmin || contextSrv.user.orgRole === OrgRole.Admin)) {
//   options.push({
//     value: 'html',
//     label: 'HTML',
//   });
// }
export const plugin = new PanelPlugin<TextOptions>(TextPanel)
  .setPanelOptions(builder => {
    builder
      .addRadio({
        path: 'mode',
        name: 'Mode',
        description: 'text mode of the panel',
        settings: {
          options: options,
        },
        defaultValue: 'markdown',
      })
      .addCustomEditor({
        id: 'content',
        path: 'content',
        name: 'Content',
        description: 'Content of the panel',
        defaultValue: `# Title

For markdown syntax help: [commonmark.org/help](https://commonmark.org/help/)
         `,
        editor: TextPanelEditor,
      });
  })
  .setMigrationHandler(textPanelMigrationHandler);
