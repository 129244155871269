import React from 'react';
import { GrafanaTheme } from '@grafana/data';
import { stylesFactory, useTheme } from '@grafana/ui';
import { css, cx } from 'emotion';

interface SimpleTableProps {
  data: SimpleTableOptions;
}

export interface SimpleTableOptions {
  columns: string[];
  rows: string[][];
  title: string;
}

export const SimpleTable: React.FC<SimpleTableProps> = ({ data }) => {
  const theme = useTheme();
  const styles = getStyles(theme);
  return (
    <div style={{ textAlign: 'center' }}>
      {data.title && <h2>{data.title}</h2>}
      <table className={cx(styles.table)}>
        <thead>
          <tr>
            {data.columns.map((column: string, index: number) => {
              return (
                <th key={index} className={cx(styles.headCell, styles.dataCell)}>
                  {column}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row: any[], row_index: number) => {
            return (
              <tr key={row_index}>
                {row.map((row_item: string, row_item_index: number) => {
                  return (
                    <td className={cx(styles.dataCell)} key={row_item_index}>
                      {row_item}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const getStyles = stylesFactory((theme: GrafanaTheme) => ({
  table: css`
    margin: 0 auto;
    border-collapse: collapse;
  `,
  headCell: css`
    background-color: #152c73;
    color: #fff;
  `,
  dataCell: css`
    padding: 0.5em 1em;
    border: ${theme.border.width.sm} solid ${theme.colors.border2};
  `,
}));
