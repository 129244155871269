import React, { memo } from 'react';
import { css } from 'emotion';
import { GrafanaTheme, TimeOption } from '@grafana/data';
import { useTheme, stylesFactory } from '../../../themes';

const getStyles = stylesFactory((theme: GrafanaTheme) => {
  return {
    container: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 4px;
      border-left: 2px solid rgba(255, 255, 255, 0);
      background: #e1e5ef;
      margin-right: 5px;
      border-radius: 2px;
      color: #0b1841;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: #1891f6;
        cursor: pointer;
        color: #ffffff;
      }
    `,
    container_active: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 7px 4px;
      border-left: 2px solid rgba(255, 255, 255, 0);
      background: #1891f6;
      margin-right: 5px;
      border-radius: 2px;
      font-weight: 500;
      color: #ffffff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:hover {
        background: #1891f6;
        cursor: pointer;
        color: #ffffff;
      }
    `,
  };
});

interface Props {
  value: TimeOption;
  selected?: boolean;
  onSelect: (option: TimeOption) => void;
}

export const TimeRangeButtonOption = memo<Props>(({ value, onSelect, selected = false }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <span
      className={selected ? styles.container_active : styles.container}
      onClick={() => onSelect(value)}
      tabIndex={-1}
    >
      {selected ? <i className="fa fa-check" /> : null}
      <span>&nbsp;&nbsp;{value.display}&nbsp;&nbsp; </span>
    </span>
  );
});
