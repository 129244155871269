import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Playlist: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3132_2182)">
        <path
          d="M21.5762 2.19141L15.747 8.02051L15.7012 8.0664H11.4785L17.3075 2.2373L17.3535 2.19141H21.3008C21.3926 2.19141 21.4843 2.19141 21.5762 2.19141ZM18.8222 8.0664L23.5957 3.29297C24.0241 3.81315 24.2383 4.42513 24.2383 5.1289V8.0664H18.8222ZM10.0097 2.19141H14.2325L8.40331 8.02051L8.35742 8.0664H4.13477L9.96381 2.2373L10.0097 2.19141ZM6.88867 2.19141L1.05957 8.02051L1.01368 8.0664H0.738281V5.1289C0.768878 4.30273 1.05957 3.61426 1.61035 3.06348C2.16113 2.5127 2.84961 2.222 3.67578 2.19141H6.88867ZM0.738281 9.53515H24.2383V19.8164C24.2076 20.6426 23.9169 21.331 23.3662 21.8818C22.8154 22.4325 22.1269 22.7232 21.3008 22.7539H3.67578C2.84961 22.7232 2.16113 22.4325 1.61035 21.8818C1.05957 21.331 0.768878 20.6426 0.738281 19.8164V9.53515ZM10.6982 12.5644C10.4229 12.7174 10.2852 12.9316 10.2852 13.207V19.082C10.2852 19.3575 10.4229 19.5715 10.6982 19.7246C10.9431 19.847 11.1878 19.8317 11.4326 19.6787L15.8388 16.7412C16.053 16.5882 16.1602 16.3893 16.1602 16.1445C16.1602 15.8997 16.053 15.7009 15.8388 15.5479L11.4326 12.6104C11.1878 12.4574 10.9431 12.442 10.6982 12.5644Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3132_2182">
          <rect width="24" height="24" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
