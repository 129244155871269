import React, { FunctionComponent } from 'react';
import { SvgProps } from './types';

export const Oreo: FunctionComponent<SvgProps> = ({ size, ...rest }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g clip-path="url(#clip0_13758_2237)">
        <path
          d="M12.3334 14.9941C12.3334 14.5504 12.6944 14.1895 13.1381 14.1895C14.2041 14.2299 14.2038 15.7585 13.1381 15.7988C12.6944 15.7988 12.3334 15.4378 12.3334 14.9941Z"
          fill="white"
        />
        <path
          d="M0.501913 13.0632L2.62745 10.2292C3.08255 -1.3465 18.7858 -3.90422 22.9716 6.7447H18.7128L17.41 5.44187C17.2779 5.3098 17.0987 5.23555 16.9119 5.23555H15.237C14.4102 2.89274 10.9747 3.42534 10.9253 5.94008C10.9749 8.45521 14.4107 8.987 15.237 6.64453H16.6201L17.923 7.94736C18.0551 8.07948 18.2343 8.15368 18.4211 8.15368H23.396C23.5138 8.6766 23.5924 9.21413 23.6289 9.76307H10.7095C9.88266 7.42026 6.4471 7.95286 6.39774 10.4676C6.44734 12.9827 9.88309 13.5145 10.7095 11.172H23.6288C23.5925 11.7175 23.5143 12.2553 23.396 12.7814H18.421C18.2342 12.7814 18.055 12.8556 17.9229 12.9878L16.62 14.2906H15.237C14.4102 11.9478 10.9746 12.4804 10.9253 14.9951C10.9749 17.5103 14.4106 18.042 15.237 15.6996H16.9118C17.0987 15.6996 17.2778 15.6253 17.41 15.4932L18.7128 14.1904H22.9735C22.3848 15.7459 21.4282 17.1536 20.16 18.2916L21.3682 23.1247C21.4208 23.3351 21.3736 23.5581 21.2401 23.7291C21.1066 23.9001 20.9017 24 20.6848 24H10.8752C10.5519 24 10.2701 23.78 10.1917 23.4664L9.59124 21.0644L4.93809 21.7291C4.58091 21.7802 4.24257 21.5528 4.15502 21.2026L2.7119 15.4301L0.674747 14.072C0.513607 13.9646 0.403847 13.7956 0.371159 13.6048C0.33847 13.414 0.385765 13.2181 0.501913 13.0632Z"
          fill="white"
        />
        <path
          d="M12.3334 5.94139C12.3334 5.4977 12.6944 5.13672 13.1381 5.13672C14.2041 5.17716 14.2038 6.70581 13.1381 6.7461C12.6944 6.74606 12.3334 6.38507 12.3334 5.94139Z"
          fill="white"
        />
        <path
          d="M7.80997 10.4668C7.80997 10.0231 8.17095 9.66211 8.61464 9.66211C9.68068 9.70255 9.68039 11.2312 8.61464 11.2715C8.17095 11.2714 7.80997 10.9105 7.80997 10.4668Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13758_2237">
          <rect width="24" height="24" fill="white" transform="matrix(-1 0 0 1 24 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
