import React, { FC, SyntheticEvent, useState } from 'react';
import { Tooltip, Form, Field, Input, VerticalGroup, LinkButton } from '@grafana/ui';
import { selectors } from '@grafana/e2e-selectors';
interface Props {
  onSubmit: (pw: string) => void;
  onSkip?: (event?: SyntheticEvent) => void;
}

interface PasswordDTO {
  newPassword: string;
  confirmNew: string;
}

export const ChangePassword: FC<Props> = ({ onSubmit, onSkip }) => {
  const submit = (passwords: PasswordDTO) => {
    onSubmit(passwords.newPassword);
  };

  let [passwordType, setPasswordType] = useState('password');
  const [passwordStrength, setPasswordStrength] = useState<string>('Password Strength');

  function checkPasswordStrength(password: string) {
    if (password.length === 0) {
      return 'Password Strength';
    }
    // Check if the password has at least one number, one uppercase, one lowercase letter, and is 10 or more characters long
    const hasNumber = /\d/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const isLengthValid = password.length >= 10;

    if (hasNumber && hasUppercase && hasLowercase && isLengthValid) {
      return 'strong';
    } else if ((hasNumber || hasUppercase || hasLowercase) && isLengthValid) {
      return 'fair';
    } else {
      return 'weak';
    }
  }

  const handlePasswordInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const currentPassword = event.target.value;
    const strength = checkPasswordStrength(currentPassword);
    setPasswordStrength(strength);
  };

  const getPasswordStrengthIndex = () => {
    const strength = passwordStrength;

    switch (strength) {
      case 'weak':
        return 1;
      case 'fair':
        return 2;
      case 'strong':
        return 3;
      default:
        return 0;
    }
  };

  const toggle_password_type = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  return (
    <Form onSubmit={submit}>
      {({ errors, register, getValues }) => (
        <>
          <div className="signup-form-heading">Reset Password</div>
          <div className="signup-form-subheading-colored text-center m-b-2">
            Password must contain at least one number, one uppercase letter, one lowercase letter, and 10 or more
            characters
          </div>
          <div style={{ position: 'relative' }}>
            <Field label="New Password" invalid={!!errors.newPassword}>
              <>
                <Input
                  className="login-form-input login-form-password"
                  autoFocus
                  ref={register({
                    required: 'New Password Required',
                  })}
                  type={passwordType}
                  name="newPassword"
                  title="Please make sure that the new password must contain at least one number, one uppercase, one lowercase letter, and 10 or more characters."
                  onInput={handlePasswordInput}
                  placeholder="Enter New Password"
                />
                <button
                  type="button"
                  onClick={toggle_password_type}
                  style={{
                    float: 'right',
                    position: 'relative',
                    marginTop: '-41px',
                    marginRight: '0',
                    border: 'none',
                    color: '#333333',
                    backgroundColor: 'Transparent',
                    padding: '10px',
                    zIndex: 10,
                  }}
                >
                  {passwordType === 'password' ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                </button>
                {!!errors.newPassword && errors.newPassword.message && (
                  <span className="badge-danger">{errors.newPassword.message}</span>
                )}
              </>
            </Field>
            <div className="strength-container">
              <div className={`strength strength-${getPasswordStrengthIndex() >= 1 ? 'weak' : ''}`} />
              <div className={`strength strength-${getPasswordStrengthIndex() >= 2 ? 'fair' : ''}`} />
              <div className={`strength strength-${getPasswordStrengthIndex() >= 3 ? 'strong' : ''}`} />
            </div>
            <div className={`strength-text strength-text${'-' + passwordStrength}`}>{passwordStrength}</div>
          </div>
          <div style={{ position: 'relative' }}>
            <Field label="Confirm New Password" invalid={!!errors.confirmNew}>
              <>
                <Input
                  className="login-form-input login-form-password"
                  type={passwordType}
                  ref={register({
                    required: 'Confirmed Password is Required',
                    validate: v => v === getValues().newPassword || 'Passwords must match!',
                  })}
                  name="confirmNew"
                  placeholder="Confirm New Password"
                />
                <button
                  type="button"
                  onClick={toggle_password_type}
                  style={{
                    float: 'right',
                    position: 'relative',
                    marginTop: '-41px',
                    marginRight: '0',
                    border: 'none',
                    color: '#333333',
                    backgroundColor: 'Transparent',
                    padding: '10px',
                    zIndex: 10,
                  }}
                >
                  {passwordType === 'password' ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                </button>
                {!!errors.confirmNew && errors.confirmNew.message && (
                  <span className="badge-danger">{errors.confirmNew.message}</span>
                )}
              </>
            </Field>
          </div>
          <VerticalGroup>
            <button className={`submit-btn`} type="submit">
              Reset my Password
            </button>

            {onSkip && (
              <Tooltip
                content="If you skip you will be prompted to change password next time you login."
                placement="bottom"
              >
                <LinkButton variant="link" onClick={onSkip} aria-label={selectors.pages.Login.skip}>
                  Skip
                </LinkButton>
              </Tooltip>
            )}
          </VerticalGroup>
        </>
      )}
    </Form>
  );
};
