import React, { FC } from 'react';
import { css, cx } from 'emotion';
// import { useTheme } from '@grafana/ui';

export interface BrandComponentProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}

const LoginLogo: FC<BrandComponentProps> = ({ className }) => {
  const maxSize = css`
    max-width: 170px;
    margin-bottom: 0px;
  `;

  return (
    <>
      <img className={cx(className, maxSize)} src="public/img/rheo-white.svg" alt="rheo-logo" />
    </>
  );
};

const HexagonalBackground = () => {
  return css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: url(public/img/hexagonal-background.png);
    background-size: cover;
  `;
};

const LoginBackground: FC<BrandComponentProps> = ({ className, children }) => {
  const background = css`
    background: url(public/img/login/login-bg.svg);
    background-size: cover;
  `;

  return <div className={cx(background, className)}>{children}</div>;
};

const MenuLogo: FC<BrandComponentProps> = ({ className }) => {
  return <img className={className} src="public/img/rheo-white.svg" alt="Rheo" />;
};

const LoginBoxBackground = () => {
  //const theme = useTheme();
  return css`
    background-size: cover;
  `;
};

export class Branding {
  static LoginLogo = LoginLogo;
  static HexagonalBackground = HexagonalBackground;
  static LoginBackground = LoginBackground;
  static MenuLogo = MenuLogo;
  static LoginBoxBackground = LoginBoxBackground;
  static AppTitle = 'Rheo';
  static LoginTitle = 'Welcome to Rheo';
  static GetLoginSubTitle = () => {
    const slogans = [
      "Don't get in the way of the data",
      'Your single pane of glass',
      'Built better together',
      'Democratising data',
    ];
    const count = slogans.length;
    return slogans[Math.floor(Math.random() * count)];
  };
}
