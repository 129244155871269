import React, { PureComponent, SyntheticEvent, ChangeEvent } from 'react';
import { selectors } from '@grafana/e2e-selectors';
import appEvents from 'app/core/app_events';
import config from 'app/core/config';
import { AppEvents } from '@grafana/data';

import { FormModel } from './LoginCtrl';
import { Input, Field } from '@grafana/ui';
import { css } from 'emotion';

let timer: number;

interface Props {
  displayForgotPassword: boolean;
  onChange?: (valid: boolean) => void;
  validateUser: (data: FormModel) => void;
  onSubmit: (data: FormModel) => void;
  login: (data: FormModel) => void;
  isLoggingIn: boolean;
  isValidatingUser: boolean;
  showCustomTooltip: boolean;
  isUserValid: boolean;
  passwordHint: string;
  loginHint: string;
  mfaEnabled: boolean;
  restrictLoginDomains: boolean;
  allowedLoginDomains: string;
  startTimeInSeconds: number;
  isForgotPasswordNoteVisible: boolean;
  errorType: string;
}

interface State {
  user: string;
  password: string;
  email: string;
  valid: boolean;
  otp: string;
  customTooltipState: boolean;
  buttonDisabled: boolean;
  timeRemainingInSeconds: number;
  resendOtpText: string;
  showPassword: boolean;
  isInvalidEmailState: boolean;
  isInValidPasswordState: boolean;
}

export const submitButton = css`
  justify-content: center;
  width: 100%;
`;

export class LoginForm extends PureComponent<Props, State> {
  private userInput: HTMLInputElement;
  private inputRef: React.RefObject<HTMLInputElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      user: '',
      password: '',
      email: '',
      valid: false,
      otp: '',
      buttonDisabled: false,
      timeRemainingInSeconds: props.startTimeInSeconds,
      resendOtpText: '',
      showPassword: false,
      isInvalidEmailState: false,
      isInValidPasswordState: false,
      customTooltipState: false,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.userInput.focus();
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.isUserValid !== prevProps.isUserValid && !this.props.isUserValid) {
      this.setState({
        password: '',
        otp: '',
        valid: false,
      });
    } else if (this.props.errorType !== prevProps.errorType && this.props.errorType !== 'INVALID_PASSWORD') {
      this.setState({
        otp: '',
      });
    }
    if (this.props.showCustomTooltip !== prevProps.showCustomTooltip) {
      this.addRemoveCustomTooltip();
    }
    if (this.props.mfaEnabled && this.props.isUserValid && this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  addRemoveCustomTooltip() {
    this.setState({ customTooltipState: true });
    setTimeout(() => {
      this.setState({ customTooltipState: false });
    }, 3000);
  }
  decrementTimeRemaining = () => {
    if (this.state.timeRemainingInSeconds > 0) {
      this.setState({
        timeRemainingInSeconds: this.state.timeRemainingInSeconds - 1,
        resendOtpText:
          'Additional Security code requests disabled for ' + this.state.timeRemainingInSeconds + ' seconds',
      });
    } else {
      this.setState(
        {
          buttonDisabled: false,
          resendOtpText: '',
        },
        () => {
          clearInterval(timer);
        }
      );
    }
  };
  validateUser = (e: SyntheticEvent) => {
    e.preventDefault();
    let { user, password, email, otp } = this.state;
    user = user.trim().toLowerCase();
    otp = otp.trim();
    this.setState({
      buttonDisabled: true,
      timeRemainingInSeconds: 30,
    });
    if (this.props.restrictLoginDomains) {
      if (this.props.allowedLoginDomains.indexOf(user.split(`@`)[1]) === -1) {
        appEvents.emit(AppEvents.alertWarning, ['Unauthorized access', []]);
      } else {
        this.setState({
          buttonDisabled: true,
          timeRemainingInSeconds: 30,
        });
        timer = window.setInterval(this.decrementTimeRemaining, 1000);
        if (this.state.valid) {
          this.props.validateUser({ user, password, email, otp });
        }
      }
    } else {
      this.setState({
        buttonDisabled: true,
        timeRemainingInSeconds: 30,
      });
      timer = window.setInterval(this.decrementTimeRemaining, 1000);
      if (this.state.valid) {
        this.props.validateUser({ user, password, email, otp });
      }
    }
  };
  loginUser = (e: SyntheticEvent) => {
    e.preventDefault();
    let { user, password, email, otp } = this.state;
    user = user.trim().toLowerCase();
    otp = otp.trim();
    if (this.props.restrictLoginDomains) {
      if (this.props.allowedLoginDomains.indexOf(user.split(`@`)[1]) === -1) {
        appEvents.emit(AppEvents.alertWarning, ['Unauthorized access', []]);
      } else {
        if (!this.props.mfaEnabled || this.state.otp.length > 0) {
          this.props.login({ user, password, email, otp });
        }
      }
    } else {
      if (!this.props.mfaEnabled || this.state.otp.length > 0) {
        this.props.login({ user, password, email, otp });
      }
    }
  };
  onChangeOTP = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      otp: e.target.value,
    });
  };
  onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      password: e.target.value,
      valid: this.validate(this.state.user, e.target.value),
    });
  };

  isInValidEmail = () => {
    // Regular expression for validating an Email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Test the email against the regex
    const isInvalid = !emailRegex.test(this.state.user);
    this.setState({
      isInvalidEmailState: isInvalid,
    });
  };

  isInValidPassword = () => {
    const isInvalid = this.state.password.length < 1;
    this.setState({
      isInValidPasswordState: isInvalid,
    });
  };

  onChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      user: e.target.value.trim().toLowerCase(),
      valid: this.validate(e.target.value, this.state.password),
    });
  };
  validate(user: string, password: string) {
    return user.length > 0 && password.length > 0;
  }
  showOrHidePassword = (e: SyntheticEvent) => {
    e.preventDefault();
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  encodeEmail(email: string) {
    return email.replace(/\+/g, '%2B');
  }

  emailFromUrl = () => {
    let forgotPasswordURL = 'user/password/send-reset-email';
    if (!this.state.isInvalidEmailState && this.state.user) {
      const encodedEmail = this.encodeEmail(this.state.user);
      forgotPasswordURL = `user/password/send-reset-email?email=${encodedEmail}`;
    }
    return forgotPasswordURL;
  };
  render() {
    return (
      <form name="loginForm" className="login-form-group gf-form-group">
        {this.props.mfaEnabled && this.props.isUserValid ? (
          <div className="form-text">
            <div className="form-text-heading">Enter Security Code</div>
            <div className="form-text-subheading text-center">
              Please verify your security code that has been sent to your email
            </div>
          </div>
        ) : (
          <div className="form-text">
            <div className="form-text-heading">Hello</div>
            <div className="form-text-subheading">
              <strong>Login</strong> with your company ID{' '}
              <span className="form-text-subheading-light-grey">(Eg: andewtate@xxxx.com)</span>
            </div>
          </div>
        )}
        <div className="login-form">
          <Field label="Email">
            <>
              <Input
                ref={input => {
                  this.userInput = input;
                }}
                type="email"
                name="user"
                required
                placeholder="Email Address"
                aria-label={selectors.pages.Login.username}
                onChange={this.onChangeUsername}
                onBlur={this.isInValidEmail}
                disabled={this.props.isUserValid ? true : false}
                className="login-form-input"
              />
              {this.state.isInvalidEmailState && (
                <span className="badge-danger">Please enter a Valid Email Address</span>
              )}
            </>
          </Field>
        </div>
        <div className="login-form input-group">
          <Field label="Password" disabled={this.props.isUserValid ? true : false}>
            <Input
              type={this.state.showPassword ? 'text' : 'password'}
              name="password"
              required
              value={this.state.password}
              ng-model="formModel.password"
              id="inputPassword"
              placeholder="Password"
              aria-label={selectors.pages.Login.password}
              onChange={this.onChangePassword}
              className="login-form-input login-form-password"
              onBlur={this.isInValidPassword}
            />
          </Field>
          {this.state.isInValidPasswordState && <span className="badge-danger">Please enter a Valid Password</span>}
          <button
            type="button"
            id="show/hide"
            style={{
              float: 'right',
              position: 'relative',
              marginTop: this.state.isInValidPasswordState ? '-80px' : '-58px',
              marginRight: '0',
              border: 'none',
              color: '#333333',
              backgroundColor: 'Transparent',
              padding: '10px',
              zIndex: 10,
            }}
            onClick={this.showOrHidePassword}
          >
            <i className={this.state.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}></i>
          </button>
        </div>
        {this.props.mfaEnabled && this.props.isUserValid ? (
          <div className="login-form">
            <Field label="Enter Security Code">
              <input
                ref={this.inputRef}
                type="otp"
                name="otp"
                className="gf-form-input login-form-input"
                style={{
                  marginBottom: '30',
                }}
                required
                value={this.state.otp}
                ng-model="formModel.otp"
                id="inputOtp"
                placeholder="Enter Security Code from Email"
                onChange={this.onChangeOTP}
              />
            </Field>
          </div>
        ) : (
          ''
        )}
        <div className="login-button-forgot-password login-tooltip">
          <a href={this.emailFromUrl()}>Forgot your Password?</a>
          <span className={`login-tooltip-tooltiptext ${this.state.customTooltipState ? 'login-tooltip-visible' : ''}`}>
            Click here to Reset your Password
          </span>
        </div>
        <div className="login-button-group">
          {!this.props.mfaEnabled || this.props.isUserValid ? (
            <button
              type="submit"
              className={`${this.props.isLoggingIn ? 'submit-btn-loading' : ''} submit-btn m-t-2`}
              onClick={this.loginUser}
              disabled={this.props.isLoggingIn}
            >
              {this.props.isLoggingIn && <div className="spinner"></div>}
              {`${this.props.mfaEnabled ? 'Verify' : 'Login'}`}
            </button>
          ) : (
            <button
              type="submit"
              aria-label={selectors.pages.Login.submit}
              className={`${this.props.isValidatingUser ? 'submit-btn-loading' : ''} submit-btn m-t-2`}
              onClick={this.validateUser}
              disabled={this.props.isValidatingUser}
            >
              {this.props.isValidatingUser && <div className="spinner"></div>}
              Login
            </button>
          )}
        </div>
        <div className="login-button-group">
          {this.props.mfaEnabled && this.props.isUserValid && (
            <div className="small login-button-resend-otp">
              {this.state.timeRemainingInSeconds <= 0 && (
                <div>
                  <button
                    type="submit"
                    aria-label={selectors.pages.Login.submit}
                    className={`${this.state.buttonDisabled ? 'submit-btn-loading' : ''} submit-btn-inverse`}
                    onClick={this.validateUser}
                    disabled={this.state.buttonDisabled}
                  >
                    Resend Security Code
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {!this.props.isUserValid ? (
          <p className="end-message-note">
            <strong>Don't have an Account?</strong> Contact your System Administrator or write to us at{' '}
            <a href="mailto:tech-support@rheoi.ai">tech-support@rheoi.ai</a> to <a href="/signup">sign up</a>
          </p>
        ) : null}
        {this.props.mfaEnabled && this.props.isUserValid ? (
          <div className="text-left p-t-1">
            <p className={'form-text-subheading'}>
              RHEO Logins are protected by <strong>Multi Factor Authentication(MFA)</strong>
            </p>
            {config.googleAuthEnabled && (
              <ol>
                <li>A Temporary Security Code is sent to your Email as a secondary form of Authentication.</li>
                <li>
                  A Temporary Security Code provided through the Google Authenticator app (Visit Preferences page for
                  more information once you login with Option 1, for the first time).
                </li>
              </ol>
            )}
          </div>
        ) : null}
      </form>
    );
  }
}
