import React from 'react';
import config from 'app/core/config';

import { updateLocation } from 'app/core/actions';
import { connect } from 'react-redux';
import { StoreState } from 'app/types';
import { PureComponent } from 'react';
import { getBackendSrv } from '@grafana/runtime';
import { hot } from 'react-hot-loader';
import appEvents from 'app/core/app_events';
import { AppEvents } from '@grafana/data';

const isOauthEnabled = () => {
  return !!config.oauth && Object.keys(config.oauth).length > 0;
};

export interface FormModel {
  user: string;
  password: string;
  email: string;
  otp: string;
}
interface Props {
  routeParams?: any;
  updateLocation?: typeof updateLocation;
  children: (props: {
    isLoggingIn: boolean;
    isValidatingUser: boolean;
    showCustomTooltip: boolean;
    isUserValid: boolean;
    changePassword: (pw: string) => void;
    isChangingPassword: boolean;
    skipPasswordChange: Function;
    validateUser: (data: FormModel) => void;
    login: (data: FormModel) => void;
    disableLoginForm: boolean;
    ldapEnabled: boolean;
    authProxyEnabled: boolean;
    disableUserSignUp: boolean;
    mfaEnabled: boolean;
    restrictLoginDomains: boolean;
    allowedLoginDomains: string;
    isOauthEnabled: boolean;
    loginHint: string;
    passwordHint: string;
    isForgotPasswordNoteVisible: boolean;
    errorType: string;
  }) => JSX.Element;
}

interface State {
  isLoggingIn: boolean;
  isValidatingUser: boolean;
  showCustomTooltip: boolean;
  isUserValid: boolean;
  isChangingPassword: boolean;
  isForgotPasswordNoteVisible: boolean;
  errorType: string;
}

export class LoginCtrl extends PureComponent<Props, State> {
  result: any = {};
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoggingIn: false,
      isValidatingUser: false,
      showCustomTooltip: false,
      isUserValid: false,
      isChangingPassword: false,
      isForgotPasswordNoteVisible: false,
      errorType: '',
    };

    if (config.loginError) {
      appEvents.emit(AppEvents.alertWarning, ['Login Failed', config.loginError]);
    }
  }

  changePassword = (password: string) => {
    const pw = {
      newPassword: password,
      confirmNew: password,
      oldPassword: 'admin',
    };
    if (!this.props.routeParams.code) {
      getBackendSrv()
        .put('/api/user/password', pw)
        .then(() => {
          this.toGrafana();
        })
        .catch((err: any) => console.log(err));
    }

    const resetModel = {
      code: this.props.routeParams.code,
      newPassword: password,
      confirmPassword: password,
    };

    getBackendSrv()
      .post('/api/user/password/reset', resetModel)
      .then(() => {
        this.toGrafana();
      });
  };

  login = (formModel: FormModel) => {
    this.setState({
      isLoggingIn: true,
    });
    if (config.mfaEnabled) {
      getBackendSrv()
        .post('/rai/api/user/otp/validate', formModel)
        .then((result: any) => {
          this.result = result;
          if (result.success === true) {
            this.toGrafana();
          } else {
            this.setState({
              isLoggingIn: false,
            });
          }
        })
        .catch((error: any) => {
          if (error.data.message === 'Invalid username or password') {
            this.setState(
              {
                isForgotPasswordNoteVisible: true,
                isUserValid: false,
                showCustomTooltip: true,
                errorType: 'INVALID_PASSWORD',
              },
              () => {
                setTimeout(() => {
                  this.setState({ showCustomTooltip: false });
                }, 3000);
              }
            );
          } else if (error.data.message === 'Invalid Security code') {
            this.setState({
              errorType: 'INVALID_OTP_INVALID',
            });
          } else if (error.data.message === 'Security code Expired') {
            this.setState({
              errorType: 'OTP_EXPIRED',
            });
          }
          this.setState({
            isValidatingUser: false,
            isLoggingIn: false,
          });
        });
    } else {
      getBackendSrv()
        .post('/login', formModel)
        .then((result: any) => {
          this.result = result;
          if (formModel.password !== 'admin' || config.ldapEnabled || config.authProxyEnabled) {
            this.toGrafana();
            return;
          } else {
            this.changeView();
          }
        })
        .catch(() => {
          this.setState({
            isLoggingIn: false,
            showCustomTooltip: true,
          });
          setTimeout(() => {
            this.setState({ showCustomTooltip: false });
          }, 3000);
        });
    }
  };
  validateUser = (formModel: FormModel) => {
    this.setState({
      isValidatingUser: true,
    });

    getBackendSrv()
      .post('/rai/api/user/otp/send', formModel)
      .then((result: any) => {
        this.result = result;
        if (result.success === true) {
          this.setState({
            isUserValid: true,
          });
        } else {
          this.setState({
            isValidatingUser: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isValidatingUser: false,
        });
      });
  };

  changeView = () => {
    this.setState({
      isChangingPassword: true,
    });
  };

  toGrafana = () => {
    // Use window.location.href to force page reload
    if (this.result.redirectUrl) {
      if (config.appSubUrl !== '' && !this.result.redirectUrl.startsWith(config.appSubUrl)) {
        window.location.href = config.appSubUrl + this.result.redirectUrl;
      } else {
        window.location.href = this.result.redirectUrl;
      }
    } else {
      window.location.href = config.appSubUrl + '/';
    }
  };

  render() {
    const { children } = this.props;
    const {
      isLoggingIn,
      isValidatingUser,
      showCustomTooltip,
      isUserValid,
      isChangingPassword,
      isForgotPasswordNoteVisible,
      errorType,
    } = this.state;
    const { login, validateUser, toGrafana, changePassword } = this;
    const {
      loginHint,
      passwordHint,
      disableLoginForm,
      ldapEnabled,
      authProxyEnabled,
      disableUserSignUp,
      mfaEnabled,
      restrictLoginDomains,
      allowedLoginDomains,
    } = config;

    return (
      <>
        {children({
          isOauthEnabled: isOauthEnabled(),
          loginHint,
          passwordHint,
          disableLoginForm,
          ldapEnabled,
          authProxyEnabled,
          disableUserSignUp,
          mfaEnabled,
          restrictLoginDomains,
          allowedLoginDomains,
          login,
          validateUser,
          isLoggingIn,
          isValidatingUser,
          showCustomTooltip,
          isUserValid,
          changePassword,
          skipPasswordChange: toGrafana,
          isChangingPassword,
          isForgotPasswordNoteVisible,
          errorType,
        })}
      </>
    );
  }
}

export const mapStateToProps = (state: StoreState) => ({
  routeParams: state.location.routeParams,
});

const mapDispatchToProps = { updateLocation };

export default hot(module)(connect(mapStateToProps, mapDispatchToProps)(LoginCtrl));
